document.addEventListener('DOMContentLoaded', () => {
  // Строка поиска
  const headerLogo = document.querySelector('.header__logo');
  const headerServices = document.querySelector('.header__services');
  const searchForm = document.querySelector('.header__search-form');

  if (!searchForm) return;

  const searchInput = searchForm.querySelector('.header__search-input');
  const clearButtonInput = searchForm.querySelector('.header__clear-button');
  const headerSearchIcon = searchForm.querySelector('.header__search-icon');
  const headerResult = searchForm.querySelector('.header-result');
  const headerResultAdd = searchForm.querySelector('.d-header__main-service-search-result');

  // const action = searchForm.action;
  const url = searchForm.getAttribute('data-url');

  clearButtonInput.addEventListener('click', function () {
    searchInput.value = '';
    clearButtonInput.style.display = 'none';
    headerSearchIcon.src = '/static-s/images/dist/fi_search.svg';

    headerLogo.classList.remove('header__logo--hide');
    headerServices.classList.remove('header__services--active');
    searchInput.classList.remove('header__search-input--active');
    headerResult && headerResult.classList.remove('header-result--active');
    headerResultAdd && headerResultAdd.classList.remove('d-header__main-service-search-result_visible');
  });

  // searchForm.addEventListener('submit', function (e) {
  //   e.preventDefault();

  //   if (action) {
  //     window.location.href = action;
  //   }
  // });

  searchInput.addEventListener('input', function () {
    if (searchInput.value.length > 0) {
      clearButtonInput.style.display = 'block';
      headerSearchIcon.src = '/static-s/images/dist/fi_search_grey.svg';

      headerLogo.classList.add('header__logo--hide');
      headerServices.classList.add('header__services--active');
      searchInput.classList.add('header__search-input--active');

      if (url) {
        const data = {};

        data[searchInput.name] = searchInput.value;

        const params = new URLSearchParams(data);

        fetch(url + `?${params.toString()}`)
          .then(response => {
            if (!response.ok) { // Проверка на ошибку HTTP
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            return response.text();
          })
          .then(data => {
            if (headerResult) {
              headerResult.innerHTML = data;
              headerResult.classList.add('header-result--active');
            }

            if (headerResultAdd) {
              headerResultAdd.innerHTML = data;
              headerResultAdd.classList.add('d-header__main-service-search-result_visible');
            }
          })
          .catch(error => console.error('Ошибка при выполнении запроса:', error));
      } else {
        console.warn('search form has not "url"');
      }
    } else {
      clearButtonInput.style.display = 'none';
      headerSearchIcon.src = '/static-s/images/dist/fi_search.svg';

      headerLogo.classList.remove('header__logo--hide');
      headerServices.classList.remove('header__services--active');
      searchInput.classList.remove('header__search-input--active');

      headerResult && headerResult.classList.remove('header-result--active');
      headerResultAdd && headerResultAdd.classList.remove('d-header__main-service-search-result_visible');

      searchInput.blur();
    }
  });

  searchInput.addEventListener('focus', function () {
    headerServices.classList.add('header__services--active');
    headerLogo.classList.add('header__logo--hide');
  });

  searchInput.addEventListener('blur', function () {
    if (searchInput.value.length === 0) {
      clearButtonInput.style.display = 'none';
      headerServices.classList.remove('header__services--active');
      headerLogo.classList.remove('header__logo--hide');
    }
  });

  searchInput.addEventListener('touchstart', (e) => {
    e.stopPropagation();
    setTimeout(function(){
      clearButtonInput.style.display = 'block';
    }, 100)
  });

  document.addEventListener('click', function (e) {
    let isTargetInElement = false;

    const element = document.querySelectorAll('.header-result, .header__search-input');

    for (let i = 0; i < element.length; i++) {
      if (element[i] === e.target || element[i].contains(e.target)) {
        isTargetInElement = true;
        break;
      }
    }

    if (!isTargetInElement) {
      for (let i = 0; i < element.length; i++) {
        element[i].classList.remove('header-result--active');
      }
    }
  });

  document.body.addEventListener('click', function (event) {
    if (event.target.matches('.d-header__main-service-search-result span[data-type="clear"]')) {
      document.querySelector('.header__search-input').value = '';
    }
  });
});
